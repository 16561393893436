
import { UserModule } from '@/store/user';
import { getRedirectUrl, removeRedirectUrl } from '@/utils/cookies';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'LoginPage',
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.handleBuzzvil();
  }

  created() {
    /* eslint-disable */
    this.lastLoginType = localStorage.getItem('lastLoginType');
    const { code, state, id_token } = (this.$route.query as any);
    if (code && state) {
      if (state === 'google') {
        UserModule.GoogleLogin(this.$route.query.code as string).then(async (res) => {
          if (res === 'login') {
            localStorage.setItem('lastLoginType', 'google');
            const redirectUrl = getRedirectUrl();
            if (redirectUrl) this.$router.push({ path: redirectUrl });
            else this.$router.push({ path: '/' });
            await removeRedirectUrl();
          }
        }).catch(() => {
          this.loading = false;
          this.$router.replace({ query: {} });
        });
      } else {
        UserModule.NaverLogin(this.$route.query.code as string).then(async (res) => {
          if (res === 'login') {
            localStorage.setItem('lastLoginType', 'naver');
            const redirectUrl = getRedirectUrl();
            if (redirectUrl) this.$router.push({ path: redirectUrl });
            else this.$router.push({ path: '/' });
            await removeRedirectUrl();
          }
        }).catch(() => {
          this.$router.replace({ query: {} });
          this.loading = false;
        });
      }
    } else if (code) {
      UserModule.KakaoLogin(this.$route.query.code as string).then(async (res) => {
        if (res === 'login') {
          localStorage.setItem('lastLoginType', 'kakao');
          const redirectUrl = getRedirectUrl();
          if (redirectUrl) this.$router.push({ path: redirectUrl });
          else this.$router.push({ path: '/' });
          await removeRedirectUrl();
        }
      }).catch(() => {
        this.$router.replace({ query: {} });
        this.loading = false;
      });
    } else {
      console.log(this.$route);
      if (this.$route.hash.includes('id_token')) {
        const idToken = this.$route.hash.substring(this.$route.hash.indexOf('id_token=') + 9);
        UserModule.AppleLogin(idToken).then(async (res) => {
          if (res === 'login') {
            localStorage.setItem('lastLoginType', 'apple');
            const redirectUrl = getRedirectUrl();
            if (redirectUrl) this.$router.push({ path: redirectUrl });
            else this.$router.push({ path: '/' });
            await removeRedirectUrl();
          }
        }).catch(() => {
          this.$router.replace({ query: {} });
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    }
    /* eslint-enable */
  }

  private loading = true;

  private lastLoginType: string | null = '';

  private handleNaverLogin() {
    (window as any).location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.VUE_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOCATION}/login&state=123qwe123qawfweer4gb56u45`;
  }

  private handleBuzzvil() {
    if (localStorage.getItem('registerFlag') === 'true') {
      (window as any).bzq('track', { action: 'bz_action_complete' });
      localStorage.removeItem('registerFlag');
    }
  }

  private handleKakaoLogin() {
    const clientId = process.env.VUE_APP_KAKAO_CLIENT_ID;
    const redirectUri = process.env.VUE_APP_KAKAO_REDIRECT_URL;
    (window as any).Kakao.init(clientId);
    (window as any).Kakao.Auth.authorize({
      redirectUri,
    });
  }

  private async handleGoogleLogin() {
    const client = (window as any).google.accounts.oauth2.initCodeClient({
      client_id: '564186867142-3jeppc2n5gibqa5c882vqdr63ls1cf93.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/userinfo.profile',
      ux_mode: 'redirect',
      redirect_uri: `${process.env.VUE_APP_LOCATION}/login`,
      state: 'google',
    });
    client.requestCode();
  }

  private handleAppleLogin() {
    window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${process.env.VUE_APP_APPLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_LOCATION}/login&response_type=code id_token&response_mode=fragment`;
  }
}
